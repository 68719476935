export const navItems = [
  {
    name: 'Projects',
    path: '/',
  },
  {
    name: 'Blogs',
    path: '/blogs',
  },
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'Contact',
    path: 'mailto:kmtna@proton.me',
  },
];